// CSS
import styles from "./app.module.scss";

// Constants
const atgLogoSrc = require("./media/images/atg-logo-white.png");

function App() {
    return (
        <div className={styles.app}>
            <div className={styles.logo}>
                <img alt="All Things Go cloud logo" src={atgLogoSrc} />
            </div>
            <div className={styles.header}>
                <h1>MANAGEMENT</h1>
            </div>
            <div className={styles.subheader}>
                <h2>
                    <a
                        href="https://allthingsgofestival.com/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        FESTIVAL
                    </a>
                    <span className={styles.divider}>|</span>
                    <a
                        href="https://www.allthingsgostudios.com/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        STUDIOS
                    </a>
                    <span className={styles.divider}>|</span>
                    <a
                        href="https://allthingsgomusic.com/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        CONTENT
                    </a>
                </h2>
            </div>
        </div>
    );
}

export default App;
